import React from "react";
// plugin that creates slider
import Logos from "../../assets/img/logos.png";
import imgAcelera from "../../assets/img/img_acelera.png";
import imgRequisitos from "../../assets/img/img_requisitos.png";
import jornada3 from "../../assets/img/jornada3.png";
import jornada1 from "../../assets/img/jornada1.png";
import jornada2 from "../../assets/img/jornada2.png";
import Img70 from "../../assets/img/70.png";
import Img20 from "../../assets/img/20.png";
import Img10 from "../../assets/img/10.png";

import Foto_01 from "../../assets/img/Foto_01.jpeg";
import Foto_01p from "../../assets/img/Foto_01.jpg";
import Foto_02 from "../../assets/img/Foto_02.jpeg";
import Foto_03 from "../../assets/img/Foto_03.jpeg";
import Foto_04 from "../../assets/img/Foto_04.jpg";
import Foto_04p from "../../assets/img/Foto_04.jpg";
import Foto_05 from "../../assets/img/Foto_05.jpeg";
import Foto_05p from "../../assets/img/Foto_05.jpg";
import Foto_06 from "../../assets/img/Foto_06.jpeg";
import Foto_06p from "../../assets/img/Foto_06.jpg";
import Foto_07 from "../../assets/img/Foto_07.jpeg";
import Foto_08 from "../../assets/img/Foto_08.jpeg";
import Foto_09 from "../../assets/img/Foto_09.jpeg";
import Foto_10 from "../../assets/img/Foto_10.jpeg";
import Foto_11 from "../../assets/img/Foto_11.jpeg";
import Foto_12 from "../../assets/img/Foto_12.jpeg";
import Foto_13 from "../../assets/img/Foto_13.jpeg";
import Foto_14 from "../../assets/img/Foto_14.jpeg";
import Foto_15 from "../../assets/img/Foto_15.jpeg";
import Foto_16 from "../../assets/img/Foto_16.jpeg";
import Foto_17 from "../../assets/img/Foto_17.jpeg";
import Foto_18 from "../../assets/img/Foto_18.jpeg";
import Foto_19 from "../../assets/img/Foto_19.jpeg";
import Foto_20 from "../../assets/img/Foto_20.jpeg";
import Foto_21 from "../../assets/img/Foto_21.jpeg";
import Foto_22 from "../../assets/img/Foto_22.jpeg";
import Foto_23 from "../../assets/img/Foto_23.jpeg";
import Foto_24 from "../../assets/img/Foto_24.jpeg";
import Foto_25 from "../../assets/img/Foto_25.jpeg";
import Foto_26 from "../../assets/img/Foto_26.jpeg";
import Foto_27 from "../../assets/img/Foto_27.jpeg";
import Foto_28 from "../../assets/img/Foto_28.jpeg";
import Foto_29 from "../../assets/img/Foto_29.jpeg";
import Foto_30 from "../../assets/img/Foto_30.jpeg";
import Foto_31 from "../../assets/img/Foto_31.jpeg";
import Foto_32 from "../../assets/img/Foto_32.jpeg";
import Foto_33 from "../../assets/img/Foto_33.jpeg";
import Foto_34 from "../../assets/img/Foto_34.jpeg";
import Foto_35 from "../../assets/img/Foto_35.jpeg";
import Foto_36 from "../../assets/img/Foto_36.jpeg";
import Depoimento01 from "../../assets/img/Depoimento1.png";
import Depoimento02 from "../../assets/img/Depoimento2.png";
import Depoimento03 from "../../assets/img/Depoimento3.png";
import Depoimento04 from "../../assets/img/Depoimento4.png";

import Video01 from "../../assets/img/Video01.jpg";
import Video02 from "../../assets/img/Video02.jpg";
import Video03 from "../../assets/img/Video03.jpg";
import Video04 from "../../assets/img/Video04.jpg";

import Gallery from 'react-grid-gallery';



// reactstrap components
import {
  Container,
  Row,
  Col,
  Button, Card, CardImg, CardBody, CardTitle, CardText, Carousel,Modal,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from "reactstrap";



function Main() {

  const [largeModal, setLargeModal] = React.useState(false);   
  const [show, setShow] =  React.useState(true);

  const [largeModal2, setLargeModal2] = React.useState(false);   
  const [show2, setShow2] =  React.useState(true);

  const [largeModal3, setLargeModal3] = React.useState(false);   
  const [show3, setShow3] =  React.useState(true);

  const [largeModal4, setLargeModal4] = React.useState(false);   
  const [show4, setShow4] =  React.useState(true);

  const [largeModal5, setLargeModal5] = React.useState(false);   
  const [show5, setShow5] =  React.useState(true);

  const [largeModal6, setLargeModal6] = React.useState(false);   
  const [show6, setShow6] =  React.useState(true);

  const [largeModal7, setLargeModal7] = React.useState(false);   
  const [show7, setShow7] =  React.useState(true);

  const [largeModal8, setLargeModal8] = React.useState(false);   
  const [show8, setShow8] =  React.useState(true);

// State for Active index
const [activeIndex, setActiveIndex] = React.useState(0);
  
// State for Animation
const [animating, setAnimating] = React.useState(false);

// Sample items for Carousel
const items = [
    {
        caption: 'Renata Carregari - Produtos e Inovação na Conexia',src: Depoimento01,
        altText: 'É um processo seletivo muito humanizado, e dentro dele pude encontrar a área que me identifico, que no meu caso é a de inovação e tecnologia. A gente não tem dimensão do tamanho do Grupo, que é muito grande e tem um objetivo muito bonito, o de transformar vidas pela Educação. Já existe uma mudança, uma transformação, e espero que possamos agregar. Realmente é transformador.'
    },
    {
      caption: 'Felipe Fernandes - Modelo de Gestão no Corporativo do Grupo SEB',src: Depoimento02,
      altText: 'O cuidado com as pessoas é excepcional. Somos todos um time e vamos juntos!'
  },
  {
    caption: 'Mariane de Castro - Transformação Digital na área Digital Corporativa do Grupo SEB',src: Depoimento03,
    altText: 'Os executivos participaram do nosso Onboarding. Tiveram esse carinho, essa preocupação em nos conhecer pessoalmente, explicar como funcionam as unidades de negócio, a amplitude do Grupo. É muito incrível poder fazer parte disso! Vamos pra cima, transformar a Educação junto com o Grupo SEB.'
},
{
  caption: 'Michelli Almeida - Trainee',src: Depoimento04,
  altText: 'Fiquei impressionada com a organização e muito feliz em entrar para uma empresa que tem toda essa preocupação e cuidado com a sua gente!'
},
];

// Items array length
const itemLength = items.length - 1

// Previous button for Carousel
const previousButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ?
        itemLength : activeIndex - 1;
    setActiveIndex(nextIndex);
}

// Next button for Carousel
const nextButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === itemLength ?
        0 : activeIndex + 1;
    setActiveIndex(nextIndex);
}

// Carousel Item Data
const carouselItemData = items.map((item) => {
    return (
        <CarouselItem            
            key={item.src}
            onExited={() => setAnimating(false)}
            onExiting={() => setAnimating(true)}
        >


          <div style={{textAlign:"center"}}>
              <img
                alt="..."
                style={{width:100, height:100}}
                className="img-circle"
                src={item.src}
              />
            </div>


          <div style={{textAlign:"center", fontWeight:400, marginBottom:20}}>
          " {item.altText} "
          </div>

          <div style={{textAlign:"center", fontWeight:600, height:100}}>
          {item.caption}
          </div>
        </CarouselItem>
    );
});



  const IMAGES =
[

  {
    src: Foto_01,
    thumbnail: Foto_01,
    thumbnailWidth: 220,
    thumbnailHeight: 340,
    caption: "Dinâmica de Grupo no Onboarding com a Gerente de Gente e Gestão, Ana Paula.",
},

{
  src: Foto_02,
  thumbnail: Foto_02,
  thumbnailWidth: 380,
  thumbnailHeight: 280,
  caption: "Fazendo um tour pela mini-cidade da escola SEB Portugal de Ribeirão Preto-SP."
},

{
  src: Foto_03,
  thumbnail: Foto_03,
  thumbnailWidth: 380,
  thumbnailHeight: 280,
  caption: "No primeiro dia de ON."
},

{
  src: Foto_04,
  thumbnail: Foto_04,
  thumbnailWidth: 380,
  thumbnailHeight: 280,
  caption: "No primeiro dia de Onboarding, conhecendo o corporativo SEB, SSS de Ribeirão Preto."
},

{
  src: Foto_05,
  thumbnail: Foto_05,
  thumbnailWidth: 420,
  thumbnailHeight: 280,
  caption: "No primeiro dia, em contato com o Arnon, Head da Maple Bear Global do Canadá."
},

{
  src: Foto_06,
  thumbnail: Foto_06,
  thumbnailWidth: 400,
  thumbnailHeight: 280,
  caption: "Nosso Grupo de Trainee 2022."
},

{
  src: Foto_07,
  thumbnail: Foto_07,
  thumbnailWidth: 280,
  thumbnailHeight: 340,
  caption: "Nossos Trainee 2022 fazendo um tour pela Concept Ribeirão Preto -SP."
},


{
  src: Foto_08,
  thumbnail: Foto_08,
  thumbnailWidth: 400,
  thumbnailHeight: 280,
  caption: "Trainee da Concept se apresentando para o Grupo."
},


{
  src: Foto_09,
  thumbnail: Foto_09,
  thumbnailWidth: 240,
  thumbnailHeight: 320,
  caption: "Trainee do Digital se apresentando para o Grupo."
},
]
 
  return (
    <>
      <div id="aceleraseb">


      <Modal
        isOpen={largeModal}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        toggle={() => setLargeModal(false)}
      >
       
  
         <iframe width="99.99%" height="450" src="https://www.youtube.com/embed/LF5HaI1roU8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

   
      </Modal>

      <Modal
        isOpen={largeModal2}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        toggle={() => setLargeModal2(false)}
      >
       
  
         <iframe width="99.99%" height="450" src="https://www.youtube.com/embed/QWAs-F1s5bM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

   
      </Modal>


      <Modal
        isOpen={largeModal3}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        toggle={() => setLargeModal3(false)}
      >
       
  
         <iframe width="99.99%" height="450" src="https://www.youtube.com/embed/o_L-vPcG_dU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

   
      </Modal>


      <Modal
        isOpen={largeModal4}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        toggle={() => setLargeModal4(false)}
      >
       
  
         <iframe width="99.99%" height="450" src="https://www.youtube.com/embed/fdYwHW3shvs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

      </Modal>


      <Modal
        isOpen={largeModal5}
        className="modal-sm"
        modalClassName="bd-example-modal-sm"
        toggle={() => setLargeModal5(false)}
      >
       
          <div style={{textAlign:"center", padding:20}}>
         <img src={Foto_01} width="100%" />
         </div>

      </Modal>

      <Modal
        isOpen={largeModal6}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        toggle={() => setLargeModal6(false)}
      >
       
          <div style={{textAlign:"center", padding:20}}>
         <img src={Foto_04} width="100%" />
         </div>

      </Modal>


      <Modal
        isOpen={largeModal7}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        toggle={() => setLargeModal7(false)}
      >
       
          <div style={{textAlign:"center", padding:20}}>
         <img src={Foto_05} width="100%" />
         </div>

      </Modal>


      <Modal
        isOpen={largeModal8}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        toggle={() => setLargeModal8(false)}
      >
       
          <div style={{textAlign:"center", padding:20}}>
         <img src={Foto_06} width="100%" />
         </div>

      </Modal>



      <Container>
                <div style={{textAlign:"center", marginTop:50, marginBottom:50}}>
                  <a href="https://www.sebsa.com.br" target="_blank">
        <img src={Logos} width="80%" />
        </a>
        </div>
        </Container>
<hr style={{borderWidth:2}} />



        <Container>
          <div className="title" style={{fontSize:42,}}>
            <span style={{fontWeight:550, color:"#662D91"}}>#AceleraSEB</span>
          </div>
          <div>          
            <Row>
            <Col sm>
              <div  style={{fontSize:14, fontWeight:400,}}>

              O <span style={{fontWeight:600}}>#AceleraSEB</span>, Programa de Jovens Talentos do <span style={{fontWeight:600}}>Grupo SEB</span>, chega a sua 2ª edição com mais oportunidades, novas vagas e com o mesmo  propósito: <span style={{fontWeight:600}}>transformar pessoas para transformarem o mundo.</span><br /><br />

Aqui você fará parte de um Grupo Global do setor da Educação sempre à frente do tempo, com infinitas possibilidades de crescimento e aprendizado em um ambiente disruptivo e inovador.<br /><br />

Estamos crescendo em ritmo acelerado, investindo fortemente em tecnologia e internacionalização de todas as nossas operações. Já somos mais de 6 mil agentes de transformação que participam ativamente da vida de mais de 350 mil estudantes em mais de 30 países, e agora você tem a oportunidade de integrar esse time. <br /><br />

<span style={{fontWeight: 600, color:"#662D91"}}>
Quer ser parte da mudança? Arrasta pra cima e vem pro Grupo SEB!
</span>


             
              </div>
              </Col>
              <Col sm>
              <img src={imgAcelera} width="100%" />;
              </Col>
            </Row>  
          </div>         
        </Container>
      </div>

   
      <div id="oprograma" style={{backgroundColor:"#662D91", paddingTop:10, paddingBottom:50, marginTop:-30}}>
        <Container>
          <div className="title" style={{fontSize:18, textAlign:"center"}}>
            <h2 style={{fontWeight:550, color:"#fff"}}>O Programa</h2>
          </div>
          <div>          
            <Row>
              <Col>
              <div  style={{fontSize:14, fontWeight:400, color:"#fff", textAlign:"center"}}>

              O <span style={{fontWeight:600,}}>#AceleraSEB</span> é o programa de trainee do Grupo SEB, com duração de até 18 meses. São diversas oportunidades em diferentes áreas de atuação e Unidades de Negócios do Grupo. <br /> <br /> 

              <span style={{fontWeight:600, fontSize:32,}}>
Confira abaixo como foi a 1ª edição e venha fazer parte da nova turma dos Jovens Talentos!
</ span>


              </div>
              <br />
                       
              </Col>
            </Row>  
          </div>       



          <Container>
            <Row>
              <Col sm>
                <Card>
                    <CardBody>                   
                    <a
   
   onClick={() => setLargeModal5(true)}
   style={{cursor:"pointer"}}
 >
                      <img src={Foto_01p} width="100%" />
                      </a>
                      <br /> &nbsp;
                    <div style={{textAlign:"center"}}>
                      <CardText style={{fontWeight: 400, fontSize:12}}>
                      Dinâmica de grupo no Onboarding dos novos Jovens Talentos.                    
                      </CardText>
                    </div>
                    </CardBody>
                </Card>
              </Col>
              <Col sm>
                <Card>
                    <CardBody>                   
                    <a
   
   onClick={() => setLargeModal6(true)}
   style={{cursor:"pointer"}}
 >
                      <img src={Foto_04p} width="100%" />
                      </a>
                      <br /> &nbsp;
                    <div style={{textAlign:"center"}}>
                      <CardText style={{fontWeight: 400, fontSize:12}}>
                      Jovens Talentos conhecendo o Corporativo SEB, na Sede Administrativa de Ribeirão Preto, no primeiro dia do evento de Onboarding.                  
                      </CardText>
                    </div>
                    </CardBody>
                </Card>
              </Col>
              <Col sm>
                <Card>
                    <CardBody>                   
                    <a
   
   onClick={() => setLargeModal7(true)}
   style={{cursor:"pointer"}}
 >
                      <img src={Foto_05p} width="100%" />
                      </a>
                      <br /> &nbsp;
                    <div style={{textAlign:"center"}}>
                      <CardText style={{fontWeight: 400, fontSize:12}}>
                      O Head da Maple Bear Global, Arno Krug, recepciona os trainees diretamente do Canadá.                   
                      </CardText>
                    </div>
                    </CardBody>
                </Card>
              </Col>
              <Col sm>
                <Card>
                    <CardBody>                   
                    <a
   
   onClick={() => setLargeModal8(true)}
   style={{cursor:"pointer"}}
 >
                      <img src={Foto_06p} width="100%" />
                      </a>
                      <br /> &nbsp;
                    <div style={{textAlign:"center"}}>
                      <CardText style={{fontWeight: 400, fontSize:12}}>
                      Nossos Jovens Talentos entusiasmados com os novos desafios.                
                      </CardText>
                    </div>
                    </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        

        </Container>

        
      </div>



      <Container id="depoimentos">
          <div  className="title" style={{fontSize:18, textAlign:"center"}}>
            <h2 style={{fontWeight:550, color:"#662D91"}}>Alguns dos nossos Jovens Talentos</h2>
          </div>
          </Container>

          <Container>
            <Row>
              <Col sm>
                <Card>
                    <CardBody>                   
                    <a
   
   onClick={() => setLargeModal(true)}
   style={{cursor:"pointer"}}
 >
                      <img src={Video01} width="100%" />
                      </a>
                      <br /> &nbsp;
                    <div style={{textAlign:"center"}}>
                      <CardText style={{fontWeight: 400, fontSize:12}}>
                      Felipe Fernandes<br />
                      <span style={{fontWeight: 600}}>Modelo de Gestão no Corporativo do Grupo SEB.</span>                     
                      </CardText>
                    </div>
                    </CardBody>
                </Card>
              </Col>
              <Col sm>
                <Card>
                    <CardBody>
                    <a
   
   onClick={() => setLargeModal2(true)}
   style={{cursor:"pointer"}}
 >
                      <img src={Video02} width="100%" />
                      </a>
                      <br /> &nbsp;
                    <div style={{textAlign:"center"}}>
                      <CardText style={{fontWeight: 400, fontSize:12}}>
                      Renata Carregari<br />
                      <span style={{fontWeight: 600}}>Produtos e Inovação<br /> na Conexia.</span>                     
                      </CardText>
                    </div>
                    </CardBody>
                </Card>
              </Col>
              <Col sm>
                <Card>
                    <CardBody>
                    <a
   
   onClick={() => setLargeModal3(true)}
   style={{cursor:"pointer"}}
 >
                      <img src={Video03} width="100%" />
                      </a>
                      <br /> &nbsp;
                    <div style={{textAlign:"center"}}>
                      <CardText style={{fontWeight: 400, fontSize:12}}>
                      Mariane de Castro<br />
                      <span style={{fontWeight: 600}}>Transformação Digital na área Digital Corporativa do Grupo SEB.</span>                     
                      </CardText>
                    </div>
                    </CardBody>
                </Card>
              </Col>
              <Col sm style={{display:"none"}}>
                <Card>
                    <CardBody>
                    <a
   
   onClick={() => setLargeModal4(true)}
   style={{cursor:"pointer"}}
 >
                      <img src={Video04} width="100%" />
                      </a>
                      <br /> &nbsp;
                    <div style={{textAlign:"center"}}>
                      <CardText style={{fontWeight: 400, fontSize:12}}>
                      Mariana Barbosa<br />
                      <span style={{fontWeight: 600}}>Business Unit <br />Franquias Maple Bear.</span>                     
                      </CardText>
                    </div>
                    </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>



          <Container>
            <Row>
              <Col sm>
                <Card>
                    <CardBody>
                     <CardText style={{fontWeight: 400, fontSize:12}}>
                    <span style={{fontSize:40, fontWeight:600}}>"</span>
                    É um processo seletivo muito humanizado, e dentro dele pude encontrar a área que me identifico, que no meu caso é a de inovação e tecnologia. A gente não tem dimensão do tamanho do Grupo, que é muito grande e tem um objetivo muito bonito, o de transformar vidas pela Educação. 
                    </CardText>

                    <div style={{textAlign:"center",  fontSize:12}}>
                      <img className="img-circle" src={Depoimento01} width="80" /><br />
                      <CardText style={{fontWeight: 400,  fontSize:12}}>
                      Renata Carregari<br />
                      <span style={{fontWeight: 600}}>Produtos e Inovação na Conexia</span>                 
                      </CardText>
                    </div>
                    </CardBody>
                </Card>
              </Col>
              <Col sm>
                <Card>
                    <CardBody>
                     <CardText style={{fontWeight: 400, fontSize:12}}>
                    <span style={{fontSize:40, fontWeight:600}}>"</span>
                    O cuidado com as pessoas é excepcional. Somos todos um time e vamos juntos!
                    </CardText>

                    <div style={{textAlign:"center",  fontSize:12}}>
                      <img className="img-circle" src={Depoimento02} width="80" /><br />
                      <CardText style={{fontWeight: 400,  fontSize:12}}>
                      Felipe Fernandes<br />
                      <span style={{fontWeight: 600}}>Modelo de Gestão no Corporativo do Grupo SEB.</span>
             
                      </CardText>
                    </div>
                    </CardBody>
                </Card>
              </Col>
              <Col sm>
                <Card>
                    <CardBody>
                     <CardText style={{fontWeight: 400, fontSize:12}}>
                    <span style={{fontSize:40, fontWeight:600}}>"</span>
                    Os executivos participaram do nosso Onboarding. Tiveram esse carinho, essa preocupação em nos conhecer pessoalmente, explicar como funcionam as unidades de negócio, a amplitude do Grupo. É muito incrível poder fazer parte disso! Vamos pra cima, transformar a Educação junto com o Grupo SEB.
                    </CardText>

   
                    <div style={{textAlign:"center",  fontSize:12}}>
                      <img className="img-circle" src={Depoimento03} width="80" /><br />
                      <CardText style={{fontWeight: 400,  fontSize:12}}>
                      Mariane de Castro<br />
                      <span style={{fontWeight: 600}}>Transformação Digital na área Digital Corporativa do Grupo SEB.</span>
                 
                      </CardText>
                    </div>
                    </CardBody>
                </Card>
              </Col>
              <Col sm>
                <Card>
                    <CardBody>
                     <CardText style={{fontWeight: 400, fontSize:12}}>
                    <span style={{fontSize:40, fontWeight:600}}>"</span>
                    Fiquei impressionada com a organização e muito feliz em entrar para uma empresa que tem toda essa preocupação e cuidado com a sua gente!
                    </CardText>

                    <div style={{textAlign:"center",  fontSize:12}}>
                      <img className="img-circle" src={Depoimento04} width="80" /><br />
                      <CardText style={{fontWeight: 400,  fontSize:12}}>
                      Michelli Almeida<br />
                      <span style={{fontWeight: 600}}>Trainee</span>
         
           
                      </CardText>
                    </div>
                    </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

     
<div id="requisitos">
  <Container>
    
    <div>          
      <Row>
        <Col sm>
        <img src={imgRequisitos} width="100%" />
        </Col>
        <Col sm>
        <div className="title" style={{fontSize:42, textAlign:"right"}}>
      <span style={{fontWeight:550, color:"#55AABB"}}>Requisitos</span><br />
    </div>
        <div  style={{fontSize:14, fontWeight:400, textAlign:"right"}}>
        <span style={{fontSize:34, fontWeight:600}}>Quer ser um dos nossos Jovens Talentos?</span><br /><br />

        Basta concluir a Graduação entre dezembro de 2018 e junho de 2022 e se conectar com o propósito que nos move: transformar pessoas para transformarem o mundo. <br /><br />

        <i>* Mais detalhes sobre os requisitos estão na descrição das vagas listadas abaixo.</i><br />&nbsp;<br />&nbsp;<br />


        




        </div>
        
        </Col>
      </Row>  
    </div>         
  </Container>
</div>


<div id="diferenciais" style={{backgroundColor:"#55AABB", paddingTop:10, paddingBottom:50}}>
        <Container>
          <div className="title" style={{fontSize:42, textAlign:"center"}}>
            <span style={{fontWeight:550, color:"#fff"}}>Um só propósito, cinco razões para integrar o Programa Jovens Talentos:

</span>
          </div>
          <div>          
           

            <Row style={{marginTop:20}}>
              <Col sm style={{textAlign:"center"}}>
                <Card>
               
                    <CardBody>
                    <i className="nc-icon nc-world-2" style={{fontSize:50}} /><br />&nbsp;
                        <CardText style={{fontWeight: 400}}>Se conectar com o propósito de <span style={{fontWeight: 600}}>mudar o mundo por meio da educação.</span></CardText>
                    </CardBody>
                </Card>
              </Col>
              <Col sm style={{textAlign:"center"}}>
              <Card>                  
                    <CardBody>
                    <i className="nc-icon nc-planet"  style={{fontSize:50}}  /><br />&nbsp;
                        <CardText style={{fontWeight: 400}}>Fazer parte de um ecossistema de educação global, presente em mais de 30 países, com <span style={{fontWeight:600,}}>marcas reconhecidas por mais de 350 mil alunos.</span></CardText>
                    </CardBody>
                </Card>
              </Col>
              <Col sm style={{textAlign:"center"}}>
              <Card>
                    <CardBody>
                    <i className="nc-icon nc-bulb-63" style={{fontSize:50}} /><br />&nbsp;
                        <CardText style={{fontWeight: 400}}><span style={{fontWeight:600,}}>Viver inovação e tecnologia</span> o tempo todo em uma empresa com um propósito pra lá de nobre.</CardText>
                    </CardBody>
                </Card>
              </Col>
              <Col sm style={{textAlign:"center"}}>
              <Card>
                    <CardBody>
                    <i className="nc-icon nc-support-17" style={{fontSize:50}} /><br />&nbsp;
                        <CardText style={{fontWeight: 400}}>Ter desafios de sobra e <span style={{fontWeight:600,}}>oportunidade de aprender</span>, na prática, todos os dias, e conviver com os maiores líderes da área de educação do país.</CardText>
                    </CardBody>
                </Card>
              </Col>
              <Col sm style={{textAlign:"center"}}>
              <Card>
                    <CardBody>
                    <i className="nc-icon nc-spaceship" style={{fontSize:50}} /><br />&nbsp;
                        <CardText style={{fontWeight: 400}}>Integrar um <span style={{fontWeight:600,}}>ambiente disruptivo e inovador</span>, com liberdade para mostrar seu trabalho e deixar sua marca.</CardText>
                    </CardBody>
                </Card>
              </Col>
            </Row>  

          </div>         
        </Container>
      </div>

      <br />


<div id="jornada">
  <Container>
    <div className="title" style={{fontSize:42, textAlign:"center"}}>
      <span style={{fontWeight:550, color:"#662D91"}}>Jornada</span>
    </div>
    <div>          
      <Row style={{marginBottom:20}}>
        <Col>
        <div  style={{fontSize:16, fontWeight:400, textAlign:"center"}}>
        Dois pilares sustentam o nosso propósito e sua jornada com a gente:
        </div>        
        </Col>
      </Row> 
      <Row>
        <Col sm style={{textAlign:"center"}}>
          &nbsp;<br />
        <img src={jornada1} width="80%" />
        <div style={{marginBottom:10, marginTop:30, fontSize:22, fontWeight:600}}>
        Protagonismo
        </div>
        <div style={{fontSize:16, fontWeight:400}}>
        No Grupo SEB você é o protagonista da sua carreira e responsável pela construção da sua história com a gente.
        </div>
        &nbsp;<br />
        </Col>
        <Col sm style={{textAlign:"center"}}>
        &nbsp;<br />
        <img src={jornada2} width="80%" />
        <div style={{marginBottom:10,  marginTop:30, fontSize:22, fontWeight:600}}>
        Inovação e Empreendedorismo
        </div>
        <div style={{fontSize:16, fontWeight:400}}>
        Nossa identidade disruptiva e tecnológica se reflete em sua jornada, e você terá a oportunidade de construir um projeto inovador na sua área de atuação.
        </div>
        &nbsp;<br />
        </Col>
      </Row>  
    </div>         
  </Container>
</div>


<div id="oprograma" style={{backgroundColor:"#662D91", paddingTop:10, paddingBottom:50, marginTop:30}}>
        <Container>
          <div className="title" style={{fontSize:42, textAlign:"center"}}>
            <span style={{fontWeight:550, color:"#fff"}}>E como você vai se desenvolver aqui dentro?
</span>
          </div>
          <div>          
            <Row>
            <Col sm style={{textAlign:"center"}}>
              &nbsp;<br />
              <img src={Img70} width="50%" />
              <div style={{fontSize:20, fontWeight:600, color:"#fff", textAlign:"center"}}>
              70% on-the-job
              </div>
              <div style={{fontSize:14, fontWeight:400, color:"#fff", textAlign:"center", marginTop:20}}>
              Isso significa que a maior parte do seu aprendizado será na prática, ou seja, aqui você aprende executando!
              </div>              
              </Col>
              <Col sm style={{textAlign:"center"}}>
              &nbsp;<br />
              <img src={Img20} width="50%" />
              <div style={{fontSize:20, fontWeight:600, color:"#fff", textAlign:"center"}}>
              20% aprendendo com os outros
              </div>
              <div style={{fontSize:14, fontWeight:400, color:"#fff", textAlign:"center", marginTop:20}}>
              Seu contato diário com outros profissionais trará insights e experiências que lhe acompanharão em toda sua jornada profissional. Além disso, você terá a oportunidade de participar do nosso exclusivo <span style={{fontWeight:600}}>Programa de Mentoria e Conexão com os stakeholders</span>, inspirados em grandes players do mercado empresarial.

              </div>              
              </Col>
              <Col sm style={{textAlign:"center"}}>
              &nbsp;<br />
              <img src={Img10} width="50%" />
              <div style={{fontSize:20, fontWeight:600, color:"#fff", textAlign:"center"}}>
              10% aprendendo com cursos e treinamentos formais
              </div>
              <div style={{fontSize:14, fontWeight:400, color:"#fff", textAlign:"center", marginTop:20}}>
              Dentro do maior Grupo de Educação do Brasil você terá acesso a treinamentos com quem mais entende do assunto! Além de conteúdos técnicos da sua área de atuação, você poderá mergulhar no universo corporativo por meio de capacitações em Gestão de Pessoas e Gestão de Negócios.
              </div>              
              </Col>
            </Row>  
          </div>         
        </Container>
      </div>


      <br />


<div id="jornada">
  <Container>
    <div className="title" style={{fontSize:42, textAlign:"center"}}>
      <span style={{fontWeight:550, color:"#662D91"}}>Atribuições de um Jovem Talento, benefícios de uma empresa gigante </span>
    </div>
    <div>          
  
      <Row>
        <Col sm style={{textAlign:"left"}}>
        <img src={jornada3} width="80%" />   <br />&nbsp;    
        </Col>
        <Col sm style={{textAlign:"left", fontSize:14, lineHeight:2, fontWeight:400}}>
        Além de um salário compatível com o mercado e do acesso a um exclusivo Programa de Mentoria de Carreira, desenhado especialmente para você e seu desenvolvimento dentro do Grupo SEB, oferecemos outros benefícios:

          <ul style={{lineHeight:3, textAlign:"left"}}>
            <li>
              <div style={{fontSize:14, fontWeight:400}}>
              Bolsa de estudos para cursos de Pós-graduação e Extensão;
              </div>
            </ li>
            <li>
              <div style={{fontSize:14, fontWeight:400}}>
              Vale-alimentação;
              </div>
            </ li>
            <li>
              <div style={{fontSize:14, fontWeight:400}}>
              Subsídio no plano de saúde;
              </div>
            </ li>
            <li>
              <div style={{fontSize:14, fontWeight:400}}>
              Plano odontológico; 
              </div>
            </ li>
            <li>
              <div style={{fontSize:14, fontWeight:400}}>
              Funcional Card;
              </div>
            </ li>
            <li>
              <div style={{fontSize:14, fontWeight:400}}>
              Seguro de Vida.
              </div>
            </ li>
                    
          </ ul>
        </Col>
      </Row>  
    </div>         
  </Container>
</div>





<div  id="oportunidades"  style={{backgroundImage:
            "url(" + require("assets/img/bg_head.jpg").default + ")", paddingTop:20, paddingBottom:0}}>
  <Container>
    <div className="title" style={{fontSize:42, textAlign:"center"}}>
      <span style={{fontWeight:550, color:"#fff"}}>Novas oportunidades 2022</span>
    </div>
    <div  style={{fontSize:24, fontWeight:400, marginTop:-30, marginBottom:20, textAlign:"center", color:"#fff"}}>
        Você conectado com o propósito que nos move.
        </div> 
    <div>          
  
      <Row>
        <Col sm style={{textAlign:"center"}}>
         

        <div style={{marginTop:0, marginBottom:50}}>

<Container>

<Row style={{marginTop:0}}>
      <Col sm>
          <div style={{textAlign:"center"}}>
          <Card>
              <CardBody>              
                  <span style={{fontWeight:400, fontSize:12}}>Especialista Contábil - Business Unit: Shared Services – Área Corporativa</span><br />
                  <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Grupo SEB </span><br /><br />  
                  <div style={{margin:10, }}>
                  <a href="oportunidades#vaga11" style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                  Vaga encerrada
                    </a>
                    </div>
                    
              </CardBody>
          </Card>        
          </div>       
        </Col>
        <Col sm>
          <div style={{textAlign:"center"}}>
          <Card>
              <CardBody>              
                  <span style={{fontWeight:400, fontSize:12}}>Especialista em Tecnologia - Business Unit: Shared Services – Área Corporativa</span><br />
                  <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Grupo SEB</span><br /><br /> 
                  <div style={{margin:10,}}>
           
                    <a href="oportunidades#vaga12" style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                  Vaga encerrada
                    </a>
                    </div>
                    
              </CardBody>
          </Card>        
          </div>       
        </Col>
        <Col sm>
          <div style={{textAlign:"center"}}>
          <Card>
              <CardBody>              
                  <span style={{fontWeight:400, fontSize:12}}>Especialista em Tecnologia - Business Unit: Ensino Superior</span><br />
                  <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Grupo SEB</span><br /><br />&nbsp;<br />
                  <div style={{margin:10,}}>
               
                    <a href="oportunidades#vaga13" style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                  Vaga encerrada
                    </a>
                    </div>
                    
              </CardBody>
          </Card>        
          </div>       
        </Col>
        <Col sm>
        <div style={{textAlign:"center"}}>
        <Card style={{paddingTop:0}}>
          <CardBody>              
                  <span style={{fontWeight:400, fontSize:12}}>Customer Experience</span><br />
                  <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Escolas Premium (Pueri Domus, Sphere e Carolina Patrício) </span><br /><br />&nbsp; 

                  <div style={{margin:10,}}>        
                    <a href="oportunidades#vaga10" style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                  Vaga encerrada
                    </a>
                    </div>          
              </CardBody>
          </Card>       
          </div> 
        </Col>
        </Row>


        <div className="title" style={{fontSize:42, textAlign:"center"}}>
      <span style={{fontWeight:550, color:"#fff"}}>Vagas encerradas</span>
    </div>

    <Row style={{marginTop:0}}>
      <Col sm>
          <div style={{textAlign:"center"}}>
          <Card>
              <CardBody>              
                  <span style={{fontWeight:400, fontSize:12}}>Produtos e Inovação</span><br />
                  <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Conexia</span><br /><br /> <br /><br />&nbsp; 
                  <div style={{margin:10,}}>
                  <a href="oportunidades#vaga1" style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                  Vaga encerrada
                    </a>
                    </div>
                    
              </CardBody>
          </Card>        
          </div>       
        </Col>
        <Col sm>
        <div style={{textAlign:"center"}}>
        <Card>
          <CardBody>              
                  <span style={{fontWeight:400, fontSize:12}}>Consultor de Tecnologia Educacional</span><br />
                  <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Maple Bear</span><br /><br /> <br />&nbsp; 
                  <div style={{margin:10,}}>
                  <a href="oportunidades#vaga2" style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                  Vaga encerrada
                    </a>
                    </div>         
              </CardBody>
          </Card>       
          </div> 
        </Col>
        <Col sm>
        <div style={{textAlign:"center"}}>
        <Card style={{paddingTop:0}}>
          <CardBody>              
                  <span style={{fontWeight:400, fontSize:12}}>MB Latam New & Transition schools Academic Customer Success Project Manager  </span><br />
                  <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Maple Bear</span><br />&nbsp; 

                  <div style={{margin:10,}}>
                  <a href="oportunidades#vaga3" style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                  Vaga encerrada
                    </a>
                    </div>        
              </CardBody>
          </Card>       
          </div> 
        </Col>

        <Col sm>
        <div style={{textAlign:"center"}}>
        <Card style={{paddingTop:0}}>
          <CardBody>              
                  <span style={{fontWeight:400, fontSize:12}}>Consultor Acadêmico</span><br />
                  <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Sphere International Schools</span><br /><br /><br />&nbsp; 

                  <div style={{margin:10,}}>
                  <a href="oportunidades#vaga4" style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                  Vaga encerrada
                    </a>
                    </div>         
              </CardBody>
          </Card>       
          </div> 
        </Col>

        <Col sm>
        <div style={{textAlign:"center"}}>
        <Card style={{paddingTop:0}}>
          <CardBody>              
                  <span style={{fontWeight:400, fontSize:12}}>Tech Lead Inovação</span><br />
                  <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Grupo SEB / Finanças </span><br /><br /><br /><br />&nbsp; 

                  <div style={{margin:10,}}>
                  <a href="oportunidades#vaga5" style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                  Vaga encerrada
                    </a>
                    </div>         
              </CardBody>
          </Card>       
          </div> 
        </Col>
        
      </Row>

    <Row style={{marginTop:0}}>
      <Col sm>
          <div style={{textAlign:"center"}}>
          <Card>
              <CardBody>              
                  <span style={{fontWeight:400, fontSize:12}}>Modelagem Financeira</span><br />
                  <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Grupo SEB / Finanças</span><br /><br /> 
                  <div style={{margin:10,}}>
                  <a href="oportunidades#vaga6" style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                  Vaga encerrada
                    </a>
                    </div>       
              </CardBody>
          </Card>        
          </div>       
        </Col>
        <Col sm>
        <div style={{textAlign:"center"}}>
        <Card>
          <CardBody>              
                  <span style={{fontWeight:400, fontSize:12}}>Customer Experience</span><br />
                  <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Escola Concept</span><br /><br />
                  <div style={{margin:10,}}>
                  <a href="oportunidades#vaga7" style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                  Vaga encerrada
                    </a>
                    </div>          
              </CardBody>
          </Card>       
          </div> 
        </Col>
        <Col sm>
        <div style={{textAlign:"center"}}>
        <Card style={{paddingTop:0}}>
          <CardBody>              
                  <span style={{fontWeight:400, fontSize:12}}>Transformação Digital</span><br />
                  <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Grupo SEB / Digital</span><br /><br />

                  <div style={{margin:10,}}>
                  <a href="oportunidades#vaga8" style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                  Vaga encerrada
                    </a>
                    </div>        
              </CardBody>
          </Card>       
          </div> 
        </Col>

        <Col sm>
        <div style={{textAlign:"center"}}>
        <Card style={{paddingTop:0}}>
          <CardBody>              
                  <span style={{fontWeight:400, fontSize:12}}>Modelo de Gestão</span><br />
                  <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Grupo SEB / Gente e Gestão </span><br /><br />

                  <div style={{margin:10,}}>
                  <a href="oportunidades#vaga9" style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                  Vaga encerrada
                    </a>
                    </div>         
              </CardBody>
          </Card>       
          </div> 
        </Col>

       
        
      </Row>
      
     
      
  </Container>
  </div>

  

        </Col>
      </Row>  
    </div>         
  </Container>
</div>

<div id="etapas"  style={{backgroundColor:"#662D91", paddingTop:10, paddingBottom:10}}>
  <Container>
    <div className="title" style={{fontSize:42, textAlign:"center"}}>
      <span style={{fontWeight:550, color:"#fff"}}>Etapas</span>
    </div>
    <div>       
      <div style={{textAlign:"center", fontSize:16, fontWeight:400, paddingBottom:20, color:"#fff"}}>
      Preparados para todas as etapas do processo?
      </div>   
      <Row>
        <Col sm>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, color:"#fff"}}>
          <i className="nc-icon nc-single-copy-04" style={{fontSize:50}} />
          </div>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, color:"#fff"}}>
          Passo 1
          </div>   
          <hr />     
          <div style={{textAlign:"center", fontSize:14, fontWeight:600, paddingBottom:10, color:"#fff"}}>
          Inscrições até
          </div>  
          <div style={{textAlign:"center", fontSize:14, fontWeight:400, paddingBottom:0, color:"#fff"}}>
          22/04/2022
          </div>
        </Col>

        <Col sm>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, color:"#fff"}}>
          <i className="nc-icon nc-align-center" style={{fontSize:50}} />
          </div>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, color:"#fff"}}>
          Passo 2
          </div>   
          <hr />     
          <div style={{textAlign:"center", fontSize:14, fontWeight:600, paddingBottom:10, color:"#fff"}}>
          Testes de fit cultural e técnicos
          </div>  
          <div style={{textAlign:"center", fontSize:14, fontWeight:400, paddingBottom:0, color:"#fff"}}>
          Para conhecer seu perfil e ver se dá match com nossa cultura empresarial, você participará de alguns testes técnicos e de fit cultural.
          </div>
        </Col>

        <Col sm>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, color:"#fff"}}>
          <i className="nc-icon nc-bulb-63" style={{fontSize:50}} />
          </div>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, color:"#fff"}}>
          Passo 3
          </div>   
          <hr />     
          <div style={{textAlign:"center", fontSize:14, fontWeight:600, paddingBottom:10, color:"#fff"}}>
          Momento Coletivo
          </div>  
          <div style={{textAlign:"center", fontSize:14, fontWeight:400, paddingBottom:0, color:"#fff"}}>
          É hora de conhecer os candidatos selecionados e partilhar essa experiência única.
          </div>
        </Col>

        <Col sm>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, color:"#fff"}}>
          <i className="nc-icon nc-chat-33" style={{fontSize:50}} />
          </div>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, color:"#fff"}}>
          Passo 4
          </div>   
          <hr />     
          <div style={{textAlign:"center", fontSize:14, fontWeight:600, paddingBottom:10, color:"#fff"}}>
          Conversa com a área de Gente e Gestão
          </div>  
          <div style={{textAlign:"center", fontSize:14, fontWeight:400, paddingBottom:0, color:"#fff"}}>
          Nesse bate-papo com o RH você terá mais detalhes sobre o Grupo e o programa, e, principalmente, vamos conhecer melhor sua trajetória e aspirações.
          </div>
        </Col>

        <Col sm>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, color:"#fff"}}>
          <i className="nc-icon nc-chat-33" style={{fontSize:50}} />
          </div>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, color:"#fff"}}>
          Passo 5
          </div>   
          <hr />     
          <div style={{textAlign:"center", fontSize:14, fontWeight:600, paddingBottom:10, color:"#fff"}}>
          Conversa com a liderança
          </div>  
          <div style={{textAlign:"center", fontSize:14, fontWeight:400, paddingBottom:0, color:"#fff"}}>
          É hora de conhecer a liderança da sua área e trocar experiências com nossos gestores!
          </div>
        </Col>

        <Col sm>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, color:"#fff"}}>
          <i className="nc-icon nc-trophy" style={{fontSize:50}} />
          </div>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, color:"#fff"}}>
          Passo 6
          </div>   
          <hr />     
          <div style={{textAlign:"center", fontSize:14, fontWeight:600, paddingBottom:10, color:"#fff"}}>
          Admissão onboarding
          </div>  
          <div style={{textAlign:"center", fontSize:14, fontWeight:400, paddingBottom:0, color:"#fff"}}>
          Agora só falta participar do treinamento de integração, entregar a documentação e comemorar!
          </div>
        </Col>
      </Row>
<br /><br />
    </div>         
  </Container>

</div>

    </>
  );
}

export default Main;
