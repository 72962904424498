
import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
import { Link, animateScroll as scroll } from "react-scroll";
import Logo from "../../assets/img/logoSEB_2.png";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 90 ||
        document.body.scrollTop > 89
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 90 ||
        document.body.scrollTop < 90
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container >
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"           
            title="Programa Jovens Talentos"
          >
             <Link to="top" spy={true} smooth={true} offset={-50} duration={1000} style={{cursor:"pointer"}}>
            <img src={Logo} width="80" />
            </Link>
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>            
            <NavItem>
              <NavLink>
                  <Link to="aceleraseb" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", fontSize:12, fontWeight:400, cursor:"pointer"}}>#AceleraSEB</Link>                
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink>
                  <Link to="oprograma" spy={true} smooth={true} offset={-100} duration={1000} style={{textTransform:"none", fontSize:12, fontWeight:400, cursor:"pointer"}}>O Programa</Link>                
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink>
                  <Link to="depoimentos" spy={true} smooth={true} offset={750} duration={1000} style={{textTransform:"none", fontSize:12, fontWeight:400, cursor:"pointer"}}>Depoimentos</Link>                
              </NavLink>
            </NavItem>

            <NavItem>
            <NavLink>
                  <Link to="requisitos" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", fontSize:12, fontWeight:400, cursor:"pointer"}}>Requisitos</Link>                
              </NavLink>
            </NavItem>
            
            <NavItem>
            <NavLink>
                  <Link to="diferenciais" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", fontSize:12, fontWeight:400, cursor:"pointer"}}>Diferenciais</Link>                
              </NavLink>
            </NavItem>

            <NavItem>
            <NavLink>
                  <Link to="jornada" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", fontSize:12, fontWeight:400, cursor:"pointer"}}>Jornada</Link>                
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink>
                  <Link to="etapas" spy={true} smooth={true} offset={-100} duration={1000} style={{textTransform:"none", fontSize:12, fontWeight:400, cursor:"pointer"}}>Etapas</Link>                
              </NavLink>
            </NavItem>
         
            <NavItem>
              <Button
                className="btn-round"
                style={{backgroundColor:"#662D91", textTransform:"none", borderColor:"transparent", fontSize:12, fontWeight:400, }}
                href="/oportunidades"
         
              >
                Oportunidades

              </Button>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
