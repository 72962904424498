import React from "react";

// reactstrap components

// core components
import IndexNavbar2 from "components/Navbars/IndexNavbar2.js";
import IndexHeader2 from "components/Headers/IndexHeader2.js";
import Footer from "components/Footers/Footer.js";

// index sections
import Oportunidades from "views/index-sections/Oportunidades.js";


function Index2() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      <div id="top"></div>
      <IndexNavbar2 />
      <IndexHeader2 />
      <div className="main" >
        <Oportunidades />  
        <Footer />
      </div>
    </>
  );
}

export default Index2;
