import React from "react";
// plugin that creates slider
import Conexia from "../../assets/img/Conexia.jpg";
import Maple from "../../assets/img/Maple.jpg";
import Sphere from "../../assets/img/Sphere.jpg";
import GrupoSEB from "../../assets/img/GrupoSEB.jpg";
import Concept from "../../assets/img/Concept.jpg";
import Premium from "../../assets/img/Premium.jpg";


import { Link, animateScroll as scroll } from "react-scroll";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button, Card, CardImg, CardBody, CardTitle, CardText
} from "reactstrap";

function Main() {
 
  return (
    <>
      <div style={{marginTop:-550, marginBottom:50}}>

        <Container>

        <Row style={{marginTop:0}}>
      <Col sm>
          <div style={{textAlign:"center"}}>
          <Card>
              <CardBody>              
                  <span style={{fontWeight:400, fontSize:12}}>Especialista Contábil - Business Unit: Shared Services – Área Corporativa</span><br />
                  <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Grupo SEB </span><br /><br />  
                  <div style={{margin:10, }}>
          
                    <Link to="vaga11" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                          Vaga encerrada
                            </Link>
                    </div>
                    
              </CardBody>
          </Card>        
          </div>       
        </Col>
        <Col sm>
          <div style={{textAlign:"center"}}>
          <Card>
              <CardBody>              
                  <span style={{fontWeight:400, fontSize:12}}>Especialista em Tecnologia - Business Unit: Shared Services – Área Corporativa</span><br />
                  <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Grupo SEB</span><br /><br /> 
                  <div style={{margin:10,}}>
         
                    <Link to="vaga12" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                          Vaga encerrada
                            </Link>
                    </div>
                    
              </CardBody>
          </Card>        
          </div>       
        </Col>
        <Col sm>
          <div style={{textAlign:"center"}}>
          <Card>
              <CardBody>              
                  <span style={{fontWeight:400, fontSize:12}}>Especialista em Tecnologia - Business Unit: Ensino Superior</span><br />
                  <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Grupo SEB</span><br /><br /><br />
                  <div style={{margin:10,}}>
          
                   <Link to="vaga13" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                          Vaga encerrada
                            </Link>
                    </div>
                    
              </CardBody>
          </Card>        
          </div>       
        </Col>
        <Col sm>
                <div style={{textAlign:"center"}}>
                <Card style={{paddingTop:0}}>
                  <CardBody>              
                          <span style={{fontWeight:400, fontSize:12}}>Customer Experience</span><br />
                          <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Escolas Premium (Pueri Domus Sphere e Carolina Patrício) </span><br /><br />&nbsp; 

                          <div style={{margin:10,}}>
             
                            <Link to="vaga10" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                          Vaga encerrada
                            </Link>
                            </div>          
                      </CardBody>
                  </Card>       
                  </div> 
                </Col>
        </Row>
      
            <Row style={{marginTop:0}}>
              <Col sm>
                  <div style={{textAlign:"center"}}>
                  <Card>
                      <CardBody>              
                          <span style={{fontWeight:400, fontSize:12}}>Produtos e Inovação</span><br />
                          <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Conexia</span><br /><br /> <br /><br />&nbsp; 
                          <div style={{margin:10,}}>
                          <Link to="vaga1" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                          Vaga encerrada
                            </Link>
                            </div>  
                            
                      </CardBody>
                  </Card>        
                  </div>       
                </Col>
                <Col sm>
                <div style={{textAlign:"center"}}>
                <Card>
                  <CardBody>              
                          <span style={{fontWeight:400, fontSize:12}}>Consultor de Tecnologia Educacional</span><br />
                          <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Maple Bear</span><br /><br /> <br />&nbsp; 
                          <div style={{margin:10,}}>
                          <Link to="vaga2" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                          Vaga encerrada
                            </Link>
                            </div>         
                      </CardBody>
                  </Card>       
                  </div> 
                </Col>
                <Col sm>
                <div style={{textAlign:"center"}}>
                <Card style={{paddingTop:0}}>
                  <CardBody>              
                          <span style={{fontWeight:400, fontSize:12}}>MB Latam New & Transition schools Academic Customer Success Project Manager  </span><br />
                          <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Maple Bear</span><br />&nbsp; 

                          <div style={{margin:10,}}>
                          <Link to="vaga3" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                          Vaga encerrada
                            </Link>
                            </div>        
                      </CardBody>
                  </Card>       
                  </div> 
                </Col>

                <Col sm>
                <div style={{textAlign:"center"}}>
                <Card style={{paddingTop:0}}>
                  <CardBody>              
                          <span style={{fontWeight:400, fontSize:12}}>Consultor Acadêmico</span><br />
                          <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Sphere International Schools</span><br /><br /><br />&nbsp; 

                          <div style={{margin:10,}}>
                          <Link to="vaga4" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                          Vaga encerrada
                            </Link>
                            </div>         
                      </CardBody>
                  </Card>       
                  </div> 
                </Col>

                <Col sm>
                <div style={{textAlign:"center"}}>
                <Card style={{paddingTop:0}}>
                  <CardBody>              
                          <span style={{fontWeight:400, fontSize:12}}>Tech Lead Inovação</span><br />
                          <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Grupo SEB / Finanças </span><br /><br /><br /><br />&nbsp; 

                          <div style={{margin:10,}}>
                          <Link to="vaga5" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                          Vaga encerrada
                            </Link>
                            </div>         
                      </CardBody>
                  </Card>       
                  </div> 
                </Col>
                
              </Row>

            <Row style={{marginTop:0}}>
              <Col sm>
                  <div style={{textAlign:"center"}}>
                  <Card>
                      <CardBody>              
                          <span style={{fontWeight:400, fontSize:12}}>Modelagem Financeira</span><br />
                          <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Grupo SEB / Finanças</span><br /><br />
                          <div style={{margin:10,}}>
                          <Link to="vaga6" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                          Vaga encerrada
                            </Link>
                            </div>       
                      </CardBody>
                  </Card>        
                  </div>       
                </Col>
                <Col sm>
                <div style={{textAlign:"center"}}>
                <Card>
                  <CardBody>              
                          <span style={{fontWeight:400, fontSize:12}}>Customer Experience</span><br />
                          <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Escola Concept</span><br /><br />
                          <div style={{margin:10,}}>
                          <Link to="vaga7" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                          Vaga encerrada
                            </Link>
                            </div>          
                      </CardBody>
                  </Card>       
                  </div> 
                </Col>
                <Col sm>
                <div style={{textAlign:"center"}}>
                <Card style={{paddingTop:0}}>
                  <CardBody>              
                          <span style={{fontWeight:400, fontSize:12}}>Transformação Digital</span><br />
                          <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Grupo SEB / Digital</span><br /><br />

                          <div style={{margin:10,}}>
                          <Link to="vaga8" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                          Vaga encerrada
                            </Link>
                            </div>        
                      </CardBody>
                  </Card>       
                  </div> 
                </Col>

                <Col sm>
                <div style={{textAlign:"center"}}>
                <Card style={{paddingTop:0}}>
                  <CardBody>              
                          <span style={{fontWeight:400, fontSize:12}}>Modelo de Gestão</span><br />
                          <span style={{fontWeight:600, fontSize:12, paddingTop:0}}>Grupo SEB / Gente e Gestão </span><br /><br />

                          <div style={{margin:10,}}>
                          <Link to="vaga9" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", borderColor:"transparent", borderRadius:4, padding:12, color:"#333", fontSize:12, fontWeight:400, cursor:"pointer", backgroundColor:"#ccc",}}>
                          Vaga encerrada
                            </Link>
                            </div>         
                      </CardBody>
                  </Card>       
                  </div> 
                </Col>

               
                
              </Row>
              

              
          </Container>

     

          <Container id="vaga11">
        <div style={{textAlign:"left", textTransform:"uppercase", fontSize:14, color:"#333", fontWeight:400}}>
        Vaga
        </div>
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#662D91", marginBottom:10}}>
        Especialista Contábil - Grupo SEB - Business Unit: Shared Services – Área Corporativa
        </div>
        <img src={GrupoSEB} width="100%" />
        <hr style={{borderWidth:4}} />
        

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Requisitos obrigatórios:
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
          <ul>
            <li>Superior em Ciências Contábeis, com Graduação entre dezembro de 2018 e junho de 2022;</li>
            <li>Desejável experiência na área contábil;</li>
            <li>Conhecimento em IFRS e CPC;</li>
            <li>Conhecimento em ERP;</li>
            <li>Inglês avançado.</li>
          </ul>
 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Sobre a vaga: 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Você trabalhará com normas internacionais de contabilidade, consolidação de balanços de empresas no exterior e operações de Joint Venture. Além disso, contribuirá para a preparação, análise e apresentação dos resultados gerenciais e conversão das demonstrações contábeis das empresas sediadas no exterior.  Aprenderá a elaborar demonstrações financeiras e notas explicativas, atuando junto à equipe do exterior.
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Modelo de trabalho
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Vaga presencial em Ribeirão Preto - SP. 
        </div>

        <div style={{textAlign:"right", display:"none"}}>
        <Button
                className="btn-round"
                style={{backgroundColor:"#662D91", textTransform:"none", borderColor:"transparent", fontSize:16, fontWeight:400, }}
                href="https://www.reachr.com.br/#/vaga/grupo/especialista-contabil-no-grupo-seb/13382/ribeirao-preto/sp"
                target="_blank"
              >
                Inscreva-se!

              </Button>
              </div>
      </Container>



      <hr style={{borderWidth:3,}} />


      <Container id="vaga12">
        <div style={{textAlign:"left", textTransform:"uppercase", fontSize:14, color:"#333", fontWeight:400}}>
        Vaga
        </div>
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#662D91", marginBottom:10}}>
        Especialista em Tecnologia - Grupo SEB - Business Unit: Shared Services – Área Corporativa
        </div>
        <img src={GrupoSEB} width="100%" />
        <hr style={{borderWidth:4}} />
        

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Requisitos obrigatórios:
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
          <ul>
            <li>Superior na área de TI/Digital, com Graduação entre dezembro de 2018 e junho de 2022;</li>
            <li>Noções básicas de Chatbots e NLP;</li>
            <li>Conhecimento em conceitos e técnicas de BI (Inteligência de Negócios);</li>
            <li>Ter atuado com a plataforma Zendesk ou similares;</li>
            <li>Ter atuado ou possuir experiência em Atendimento ao Cliente;</li>
            <li>Ter experiência com alguma solução de chatbot.</li>
          </ul>
 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Sobre a vaga: 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Você trabalhará com análise das interações dos usuários com os bots, extraindo, transformando e gerando as visualizações destes dados. Aprenderá a realizar análises quantitativas e qualitativas, criticando e propondo modificações nos fluxos de conversa dos chatbots a fim de evoluir a árvore de decisão.  Acompanhará e analisará as métricas de performance dos chatbots, realizando as mudanças necessárias nos fluxos para garantir as melhorias. Terá a oportunidade de apresentar as métricas utilizadas e as análises realizadas ao time e aos clientes. Fará monitoramento proativo de missões críticas, como por exemplo validação do CPF, envio de 2ª via de boleto, etc.
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Modelo de trabalho
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Vaga presencial em Ribeirão Preto - SP.
        </div>

        <div style={{textAlign:"right", display:"none"}}>
        <Button
                className="btn-round"
                style={{backgroundColor:"#662D91", textTransform:"none", borderColor:"transparent", fontSize:16, fontWeight:400, }}
                href="https://www.reachr.com.br/#/vaga/grupo/especialista-em-tecnologia-no-grupo-seb/13386/ribeirao-preto/sp"
                target="_blank"
              >
                Inscreva-se!

              </Button>
              </div>
      </Container>



      <hr style={{borderWidth:3,}} />


      <Container id="vaga13">
        <div style={{textAlign:"left", textTransform:"uppercase", fontSize:14, color:"#333", fontWeight:400}}>
        Vaga
        </div>
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#662D91", marginBottom:10}}>
        Especialista em Tecnologia – Grupo SEB - Business Unit: Ensino Superior
        </div>
        <img src={GrupoSEB} width="100%" />
        <hr style={{borderWidth:4}} />
        

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Requisitos obrigatórios:
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
          <ul>
            <li>Superior em Engenharia, Sistemas de Informação ou Ciência da Computação, com Graduação entre dezembro de 2018 e junho de 2022;</li>
            <li>Habilidade de negociação, capacidade de inovação e perfil comunicativo;</li>
            <li>Ter disponibilidade para viagens;</li>
            <li>Inglês.</li>
          </ul>
 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Sobre a vaga: 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Você analisará gaps relacionados a experiência dos alunos, sugerindo e desenvolvendo soluções e projetos de melhoria contínua. Também participará de projetos e ações com foco na melhoria da experiência dos estudantes em todo seu ciclo acadêmico conosco.
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Modelo de trabalho
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Vaga presencial em São Paulo - SP.
        </div>

        <div style={{textAlign:"right", display:"none"}}>
        <Button
                className="btn-round"
                style={{backgroundColor:"#662D91", textTransform:"none", borderColor:"transparent", fontSize:16, fontWeight:400, }}
                href="https://www.reachr.com.br/#/vaga/grupo/especialista-em-tecnologia-no-grupo-seb/13380/sao-paulo/sp"
                target="_blank"
              >
                Inscreva-se!

              </Button>
              </div>
      </Container>





      <hr style={{borderWidth:3,}} />


      <Container id="vaga10">
        <div style={{textAlign:"left", textTransform:"uppercase", fontSize:14, color:"#333", fontWeight:400}}>
        Vaga
        </div>
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#662D91", marginBottom:10}}>
        Customer Experience - Escolas Premium (Pueri Domus, Sphere e Carolina Patrício)
        </div>
        <img src={Premium} width="100%" />
        <hr style={{borderWidth:4}} />
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Quem somos? 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        As Escolas Premium são representadas pelas marcas Pueri Domuns, Sphere e Carolina Patrício. e são referência e excelência em educação bilíngue,. Tem com seu aluno o compromisso na construção do conhecimento e de valores éticos, tornando-o cidadão do mundo, capaz de sonhar, criar e realizar.
 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Conheça mais sobre a vaga 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Fazer parte do nosso time das Escolas Premium do Grupo SEB é trabalhar para que nossos estudantes estejam sempre no centro de nossas decisões. Buscamos um(a) especialista em CX com muita vontade de aprimorar nossos processos, trazendo soluções rápidas para um atendimento cada vez mais eficaz.                          

        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        O que se espera do candidato
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Ter concluído ou estar cursando Marketing, Comunicação, administração ou áreas afins. Que seja alguém craque em:
        
        <br /><br />Identificar as fontes de insatisfação dos nossos estudantes e familiares;<br /><br />
        
         Elaborar e suportar com indicadores os projetos da unidade, uma mentalidade cada vez mais centrada no cliente. <br /><br />
         Acompanhar inciativas ligadas ao: NPS, Cliente Oculto, Zendesk etc. Lidar com tecnologias e soluções para melhorar a experiência dos clientes nos quesitos:<br /><br />

Estratégia de CX (Customer Experience);<br />
Conhecimento do cliente;<br />
Design da experiência;<br />
Mensuração da experiência (análise de indicadores);<br />
Governança de CX;<br />
Cultura da experiência. 





 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Modelo de trabalho
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        São Paulo - Presencial.
 
        </div>
        <div style={{textAlign:"right", display:"none"}}>
        <Button
                className="btn-round"
                style={{backgroundColor:"#662D91", textTransform:"none", borderColor:"transparent", fontSize:16, fontWeight:400, }}
                href="https://www.reachr.com.br/#/vaga/grupo/customer-experience-na-escolas-premim-do-grupo-seb/13475/sao-paulo/sp"
                target="_blank"
              >
                Inscreva-se!

              </Button>
              </div>
      </Container>


      <hr style={{borderWidth:3,}} />


      <Container id="vaga1" style={{marginTop:50}}>
        <div style={{textAlign:"left", textTransform:"uppercase", fontSize:14, color:"#333", fontWeight:400}}>
        Vaga
        </div>
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#662D91", marginBottom:10}}>
        Produtos e Inovação - Conexia
        </div>
        <img src={Conexia} width="100%" />
        <hr style={{borderWidth:4}} />
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Quem somos? 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Já pensou em fazer parte da empresa de soluções educacionais mais inovadora do país?<br /><br />

Prazer! Nós somos a Conexia Educação e fazemos parte do Grupo SEB, o maior grupo de Educação Básica do Brasil!<br /><br />

Nascemos com um DNA digital, uma visão inovadora e uma cultura ágil, com um único objetivo: desenvolver, agora, a educação do futuro.<br /><br />

Aqui, a tecnologia é a chave para potencializar a aprendizagem e mudar a vida de mais de 200 mil alunos, em mais de 400 escolas em todo o Brasil.<br /><br />

Vamos juntos transformar a educação!

 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Conheça mais sobre a vaga 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Se você é recém-formado, criativo e está ligado nas novidades e tendências do mercado de tecnologia e inovação, essa vaga é sua!<br /><br />

Você fará parte do desenvolvimento de produtos educacionais junto a uma equipe plural, dinâmica e que busca soluções inovadoras para escolas de todo o Brasil.<br /><br />

Curtiu? Então, venha fazer parte do time que está transformando a educação brasileira. #VemSerSEB

 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        O que se espera do candidato
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Buscamos profissionais formados em alguns destes cursos:<br /><br />

•	Engenharia;<br />
•	Administração;<br />
•	Pedagogia;<br />
•	Áreas voltadas à tecnologia.<br /><br />

Ah, ser proativo, com boa capacidade de análise e inovação, e muita vontade de se desenvolver são os diferenciais. Também é desejável flexibilidade para viagens e inglês avançado.

 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Modelo de trabalho
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Home office.
        </div>

        
       
      </Container>

      <hr style={{borderWidth:3,}} />

      <Container id="vaga2">
        <div style={{textAlign:"left", textTransform:"uppercase", fontSize:14, color:"#333", fontWeight:400}}>
        Vaga
        </div>
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#662D91", marginBottom:10}}>
        Consultor de Tecnologia Educacional
        </div>
        <img src={Maple} width="100%" />
        <hr style={{borderWidth:4}} />
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Quem somos? 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Você conhece a Maple Bear? Temos mais de 550 escolas de Ensino Infantil, Fundamental e Médio e estamos presentes em mais de 30 países com o melhor da educação bilíngue canadense. Na Maple Bear, preparamos nossos estudantes para um mundo em que a imaginação, o raciocínio crítico, o multiculturalismo e a capacidade de integrar pessoas e disciplinas serão, cada vez mais, determinantes. Desta forma, o estudante é protagonista do seu aprendizado, conquistando sua autonomia e desenvolvendo novas habilidades em um ambiente seguro.
 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Conheça mais sobre a vaga 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Já pensou em trabalhar na área de tecnologia da Mapple Bear, que tem um modelo pioneiro em bilinguismo e é classificado entre os melhores do mundo, aprendendo e apoiando um corpo docente da América Latina para traduzir as metas de tecnologia educacional em pacotes específicos de serviços, software, hardware e processos de TI? Se você topar esse desafio, contamos com o seu conhecimento para mergulhar conosco em um projeto de suporte e treinamento para implementação bem-sucedida de ferramentas de ensino e aprendizagem MB Digital. <br /><br />
#VemSerSEB!
 
 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        O que se espera do candidato
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Ter concluído ou estar cursando Letras, Pedagogia, Ciências Sociais, Comunicação Social, Design Instrucional, Ciências de Dados e áreas afins. Inglês fluente, flexibilidade para viagens. Buscamos pessoas que encontram realização pessoal através do aprendizado, apreciam a cooperação, valorizam a diversidade, sejam críticas, curiosas, proativas e abertas a desenvolverem estratégias múltiplas de comunicação.
 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Modelo de trabalho
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        São Paulo - Home office/Híbrido. 
 
        </div>
       
      </Container>

      <hr style={{borderWidth:3,}} />

      <Container id="vaga3">
        <div style={{textAlign:"left", textTransform:"uppercase", fontSize:14, color:"#333", fontWeight:400}}>
        Vaga
        </div>
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#662D91", marginBottom:10}}>
        MB Latam New & Transition Schools Academic Customer Success Project Manager  
        </div>
        <img src={Maple} width="100%" />
        <hr style={{borderWidth:4}} />
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Quem somos? 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Você conhece a Maple Bear? Temos mais de 550 escolas de Ensino Infantil, Fundamental e Médio e estamos presentes em mais de 30 países com o melhor da educação bilíngue canadense. Na Maple Bear, preparamos nossos estudantes para um mundo em que a imaginação, o raciocínio crítico, o multiculturalismo e a capacidade de integrar pessoas e disciplinas serão, cada vez mais, determinantes. Desta forma, o estudante é protagonista do seu aprendizado, conquistando sua autonomia e desenvolvendo novas habilidades em um ambiente seguro.
 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Conheça mais sobre a vaga 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Já pensou em trabalhar na Maple Bear, que tem modelo pioneiro em bilinguismo e é classificado entre os melhores do mundo, e ser responsável por gerenciar processos que monitoram o nível de preparação acadêmica das escolas para uma abertura bem-sucedida dos Programas Maple Bear? Venha ser parte do time responsável pelas ações que nos levarão a atingirmos a meta de abertura acadêmica com sucesso. <br /><br />#VemSerSEB!
 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        O que se espera do candidato
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Ter concluído ou estar cursando Letras, Design Instrucional, Ciência de Dados, Pedagogia, Ciências Sociais, Comunicação Social e áreas afins. Inglês fluente, flexibilidade para viagens. Buscamos pessoas que querem aprender, que têm habilidades em comunicação, sabem pedir ajuda e gostem de trabalhar em um ambiente colaborativo. 
 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Modelo de trabalho
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        São Paulo - Home office/Híbrido. 
 
        </div>
      
      </Container>


      <hr style={{borderWidth:3,}} />

      <Container id="vaga4">
        <div style={{textAlign:"left", textTransform:"uppercase", fontSize:14, color:"#333", fontWeight:400}}>
        Vaga
        </div>
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#662D91", marginBottom:10}}>
        Consultor Acadêmico - Sphere International Schools
        </div>
        <img src={Sphere} width="100%" />
        <hr style={{borderWidth:4}} />
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Quem somos? 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        A Sphere International School é uma rede de escolas inovadoras, bilíngues e internacionais concebida a partir das referências da educação e cultura brasileira. Idealizada pelos nossos fundadores como a escola dos sonhos para seus próprios filhos. Aqui na Sphere International School somos uma rede de escolas bilíngues e internacionais concebida a partir das referências da educação e cultura brasileira. Rompemos paradigmas ao nos tornar a primeira escola brasileira a obter a certificação do IB (International Baccalaureate), nos programas PYP (Primary Years Programme) e MYP (Middle Years Programme) no Brasil, programas antes oferecidos exclusivamente por escolas internacionais vinculadas a países estrangeiros.<br /><br />

Com foco no rigor acadêmico e um programa bilíngue de excelência, temos um projeto pedagógico que integra o currículo nacional ao internacional e valoriza o pensar e agir em duas ou mais línguas. Por meio de uma aprendizagem ativa e atualizada, desenvolvemos habilidades como o pensamento crítico, a criatividade e a colaboração, com o objetivo de formar jovens lideranças que fazem a diferença no mundo!



 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Conheça mais sobre a vaga 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Estamos em busca de talentos que estejam superinteressados em aprender muito com a nossa equipe acadêmica. Aqui, você vai participar do desenvolvimento de processos, conteúdos e práticas para a implantação do currículo Sphere nas escolas franqueadas.<br /><br />

Isso quer dizer que poderá apoiar as formações acadêmicas e o atendimento aos franqueados, e ainda acompanhar os nossos processos de Quality Assurance, produzindo conteúdos complementares com base nos manuais, processos e no programa Sphere, além de participar do processo editorial da coleção Sphere. Prepare-se para esse desafio e venha fazer parte do nosso time, #VemSerSEB!



 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        O que se espera do candidato
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Ter concluído ou estar cursando Letras, Pedagogia, Ciências Sociais, Comunicação Social e áreas afins. Ter disponibilidade para viagens. Buscamos pessoas que querem aprender, que têm habilidades em comunicação, sabem oferecer e pedir ajuda e curtem diferencial, assim como organização e gestão de projetos (uso de ferramentas como Trello, Teams, Google Drive etc. ).
 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Modelo de trabalho
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        São Paulo - Home office/Híbrido.
 
        </div>
        
      </Container>


      <hr style={{borderWidth:3,}} />

      <Container id="vaga5">
        <div style={{textAlign:"left", textTransform:"uppercase", fontSize:14, color:"#333", fontWeight:400}}>
        Vaga
        </div>
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#662D91", marginBottom:10}}>
        Tech Lead Inovação Grupo SEB: Finanças – Corporativa
        </div>
        <img src={GrupoSEB} width="100%" />
        <hr style={{borderWidth:4}} />
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Quem somos? 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Prazer! Somos o Grupo SEB!
Um Grupo de educação sempre à frente do tempo. Estamos focados em propostas pedagógicas disruptivas e inovadoras, que desenvolvam diferentes habilidades em nossos estudantes, preparando-os para os desafios do futuro, em qualquer lugar do mundo!<br /><br />

Hoje, somos um time de mais de 6 mil agentes de transformação que participam ativamente da vida de mais de 310 mil alunos em 30 países, seja atuando nas nossas escolas próprias e franquia, seja  desenvolvendo soluções inovadoras na Conexia, nossa editech.
Nosso propósito é transformar pessoas por meio da educação, porque acreditamos que pessoas curiosas, com senso crítico, criatividade e autonomia, ancoradas em valores humanos e na aprendizagem contínua, são capazes de transformar o mundo.<br /><br />

Por isso, somos arrojados e crescemos em ritmo acelerado, investindo fortemente na digitalização, internacionalização e na premiunização ou diferenciação de todas as nossas operações, voltadas para diferentes segmentos de ensino e para diferentes classes socioeconômicas.<br /><br />

Temos muito orgulho da nossa origem. Nascemos do empreendedorismo de nossos fundadores, um casal de educadores visionários que sempre colocaram a qualidade do ensino em primeiro lugar e enxergaram a escola como potencial agente de integração de diferentes vivências e culturas. Não é à toa que, em nossos mais de 60 anos, os alunos estão no centro de todas as nossas decisões, e investimos fortemente em tecnologia e no intraempreendedorismo como aliados da melhor experiência aos estudantes e famílias.
Venha conhecer nosso ecossistema de educação e transformar pessoas e o mundo com a gente.



 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Conheça mais sobre a vaga 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Desenvolver a mudança, apoiando na jornada de transformação digital nas áreas de finanças, identificando iniciativas e melhorias, através da implantação de ferramentas e processos, que aumentem a capacidade analítica das decisões e execução da estratégia, apoiando nos desenhos e implantação de análises com dados estruturados (Business Inteligence, data-driven, analytics, entre outros) e oportunidades de automação nos processos financeiros.

 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        O que se espera do candidato
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Ter concluído ou estar cursando Administração, economia e áreas afins. Inglês avançado e flexibilidade para viagens. Buscamos pessoas que querem aprender, que têm habilidades em comunicação, sabem pedir ajuda e gostam de trabalhar em um ambiente colaborativo. Espírito de equipe e liderança, para atuar em diversas SQUAD's e áreas do grupo. Capacidade analítica, lógica e criativa para resoluções de questões complexas de forma clara e consistente. Conhecimento em ferramentas de BI, Analytics, Python serão considerados diferenciais.

 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Modelo de trabalho
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        São Paulo, SP - Híbrido.  
        </div>

       
      </Container>



      <hr style={{borderWidth:3,}} />

      <Container id="vaga6">
        <div style={{textAlign:"left", textTransform:"uppercase", fontSize:14, color:"#333", fontWeight:400}}>
        Vaga
        </div>
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#662D91", marginBottom:10}}>
        Modelagem Financeira - Grupo SEB: Finanças - Corporativa
        </div>
        <img src={GrupoSEB} width="100%" />
        <hr style={{borderWidth:4}} />
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Quem somos? 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Prazer! Somos o Grupo SEB!
Um Grupo de educação sempre à frente do tempo. Estamos focados em propostas pedagógicas disruptivas e inovadoras, que desenvolvam diferentes habilidades em nossos estudantes, preparando-os para os desafios do futuro, em qualquer lugar do mundo!<br /><br />

Hoje, somos um time de mais de 6 mil agentes de transformação que participam ativamente da vida de mais de 310 mil alunos em 30 países, seja atuando nas nossas escolas próprias e franquias, seja desenvolvendo soluções inovadoras na Conexia, nossa editech.
Nosso propósito é transformar pessoas por meio da educação, porque acreditamos que pessoas curiosas, com senso crítico, criatividade e autonomia, ancoradas em valores humanos e na aprendizagem contínua, são capazes de transformar o mundo.<br /><br />

Por isso, somos arrojados e crescemos em ritmo acelerado, investindo fortemente na digitalização, internacionalização e na premiunização ou diferenciação de todas as nossas operações, voltadas para diferentes segmentos de ensino e para diferentes classes socioeconômicas.<br /><br />

Temos muito orgulho da nossa origem. Nascemos do empreendedorismo de nossos fundadores, um casal de educadores visionários que sempre colocaram a qualidade do ensino em primeiro lugar e enxergaram a escola como potencial agente de integração de diferentes vivências e culturas. Não é à toa que, em nossos mais de 60 anos, os alunos estão no centro de todas as nossas decisões, e investimos fortemente em tecnologia e no intraempreendedorismo como aliados da melhor experiência aos estudantes e famílias.
Venha conhecer nosso ecossistema de educação e transformar pessoas e o mundo com a gente.



 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Conheça mais sobre a vaga 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Estamos em busca de talentos que estejam superinteressados em aprender muito com a nossa equipe. Aqui, você vai participar do desenvolvimento de processos, estruturar relatórios e acompanhamentos, através de modelos financeiros estruturados;<br /><br />

Apoiar em projetos estruturantes, novos investimentos, M&A/deals;<br /><br />

Apoiar no planejamento estratégico e desenvolvimento dos negócios/business units;<br /><br />

Criar acompanhamentos e KPIs através de Benchmarks (internos e externos).



 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        O que se espera do candidato
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Ter concluído ou estar cursando Administração, economia e áreas afins. Inglês avançado e flexibilidade para viagens. Buscamos pessoas que querem aprender, que têm habilidades em comunicação, sabem pedir ajuda e gostam de trabalhar em um ambiente colaborativo. Espírito de equipe e liderança. Capacidade analítica, lógica e criativa para resoluções de questões complexas de forma clara e consistente. Capacidade analítica, lógica e criativa para resoluções de questões complexas de forma clara e consistente; Conhecimento em ferramentas de BI, Analytics, Python e Excel Avançado serão  considerados diferenciais.

 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Modelo de trabalho
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        São Paulo, SP - Híbrido.
 
        </div>
       
      </Container>




      <hr style={{borderWidth:3,}} />

      <Container id="vaga7">
        <div style={{textAlign:"left", textTransform:"uppercase", fontSize:14, color:"#333", fontWeight:400}}>
        Vaga
        </div>
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#662D91", marginBottom:10}}>
        Customer Experience - Escola Concept
        </div>
        <img src={Concept} width="100%" />
        <hr style={{borderWidth:4}} />
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Quem somos? 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
   A Escola CONCEPT é uma escola brasileira bilíngue internacional que fomenta o aprendizado contínuo dos estudantes por meio de experiências inovadoras, criativas e inspiradoras. Tais experiências trazem a concretude do conteúdo e sua aplicação no mundo real.<br /><br />

Nossas unidades são localizadas em São Paulo, Salvador e Ribeirão Preto. A Escola Concept abrange do Ensino Infantil ao Ensino Médio.<br /><br />

Nossos estudantes aprendem em um ambiente de aprendizagem com foco em projetos. Nosso projeto pedagógico desenvolve as habilidades e competências de nossos estudantes para que possam colaborar, co-criar, abraçar uma mentalidade empreendedora, compreender o significado de viver de forma sustentável, e ser cidadãos globais e digitalmente fluentes.

 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Conheça mais sobre a vaga 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        A Escola Concept é uma escola que vê o design como uma esfera onde design estratégico, customer experience, design de serviços e marketing 4.0 formam o nosso core para colaboração eficaz com o time pedagógico. O profissional de customer experience é responsável por acompanhar toda a trajetória do consumidor durante o período que está se relacionando com a empresa com base no estudo sobre a sua experiência em cada ponto da jornada de compra.<br /><br />

O profissional de Customer Experience irá:<br /><br />

●	Analisar as métricas de experiência do cliente;<br />
●	Desenvolver ações que possam contribuir para o aumento da lealdade e satisfação do cliente;<br />
●	Entender como funcionam todas as interações com o cliente no dia a dia;<br />
●	Colaborar com todas as áreas que impactam a experiência do cliente na definição de ações de melhoria.

 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        O que se espera do candidato
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Visão analítica para saber como quantificar as experiências dos clientes a fim de propor boas ações para aumentar o NPS. Analisar os resultados e orientar a empresa sobre quais são as métricas mais importantes é essencial para determinar as decisões a serem tomadas.<br /><br />

Curiosidade para investigar e entender o que o cliente precisa.  Assim, fica mais fácil saber como o cliente se sente em cada ponto da jornada de compra e usar isso a favor da organização.<br /><br />

Ter concluído Comunicação, Administração, Marketing ou áreas afins. Inglês fluente e conhecimento em análise e visualização de dados, entre outros. Mas, acima de tudo, ter um mindset de crescimento. Se você tem esse perfil, aqui é o lugar para começar sua jornada de crescimento!

 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Modelo de trabalho
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        São Paulo - Presencial.
 
        </div>
       
      </Container>


      <hr style={{borderWidth:3,}} />

      <Container id="vaga8">
        <div style={{textAlign:"left", textTransform:"uppercase", fontSize:14, color:"#333", fontWeight:400}}>
        Vaga
        </div>
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#662D91", marginBottom:10}}>
        Transformação Digital - Grupo SEB: Digital Corporativa
        </div>
        <img src={GrupoSEB} width="100%" />
        <hr style={{borderWidth:4}} />
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Quem somos? 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Um Grupo de educação sempre à frente do tempo. <br /><br />
        
        Estamos focados em propostas pedagógicas disruptivas e inovadoras, que desenvolvam diferentes habilidades em nossos estudantes, preparando-os para os desafios do futuro, em qualquer lugar do mundo!<br /><br />

Hoje, somos um time de mais de 6 mil agentes de transformação que participam ativamente da vida de mais de 310 mil alunos em 30 países, seja atuando nas nossas escolas próprias e franquias, seja desenvolvendo soluções inovadoras na Conexia, nossa editech.<br /><br />

Nosso propósito é transformar pessoas por meio da educação, porque acreditamos que pessoas curiosas, com senso crítico, criatividade e autonomia, ancoradas em valores humanos e na aprendizagem contínua, são capazes de transformar o mundo.<br /><br />

Por isso, somos arrojados e crescemos em ritmo acelerado, investindo fortemente na digitalização, internacionalização e na premiunização ou diferenciação de todas nossas operações, voltadas para diferentes segmentos de ensino e para diferentes classes socioeconômicas.<br /><br />

Temos muito orgulho da nossa origem. Nascemos do empreendedorismo de nossos fundadores, um casal de educadores visionários que sempre colocaram a qualidade do ensino em primeiro lugar e enxergaram a escola como potencial agente de integração de diferentes vivências e culturas. <br /><br />

Não é à toa que, em nossos mais de 60 anos, os alunos estão no centro de todas as nossas decisões, e investimos fortemente em tecnologia e no intraempreendedorismo como aliados da melhor experiência aos estudantes e famílias.<br /><br />

Venha conhecer nosso o ecossistema de educação e transformar pessoas e o mundo com a gente!


 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Conheça mais sobre a vaga 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        O time de Digital está buscando jovens talentosos, que se motivam com grandes desafios e constantemente buscam se desenvolver. Através das vagas de Digital, você terá a oportunidade de se desenvolver tanto tecnicamente quanto na área de gestão.

 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        O que se espera do candidato
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Ter concluído ou estar cursando Análise de Sistemas, Ciência da Computação, Engenharias ou áreas de exatas. Você terá a oportunidade de assumir funções como Tech Leader*, Scrum Master, Product Owner, Cientista de Dados* ou Engenharia de Dados. Estamos buscando pessoas curiosas, dinâmicas, articuladas, que constantemente desafiam o status quo, são entusiastas de tecnologia e inovação e querem transformar o mundo através da educação. Conhecimento de inglês é um diferencial.

 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Modelo de trabalho
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Ribeirão Preto, SP - Híbrido.
 
        </div>
        
      </Container>


      <hr style={{borderWidth:3,}} />

      <Container id="vaga9">
        <div style={{textAlign:"left", textTransform:"uppercase", fontSize:14, color:"#333", fontWeight:400}}>
        Vaga
        </div>
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#662D91", marginBottom:10}}>
        Modelo de Gestão - Grupo SEB: Gente e Gestão Corporativa
        </div>
        <img src={GrupoSEB} width="100%" />
        <hr style={{borderWidth:4}} />
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Quem somos? 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Um Grupo de educação sempre à frente do tempo.<br /><br />

Estamos focados em propostas pedagógicas disruptivas e inovadoras, que desenvolvam diferentes habilidades em nossos estudantes, preparando-os para os desafios do futuro, em qualquer lugar do mundo!<br /><br />

Hoje, somos um time de mais de 6 mil agentes de transformação que participam ativamente da vida de mais de 310 mil alunos em 30 países, seja atuando nas nossas escolas próprias e franquias, seja desenvolvendo soluções inovadoras na Conexia, nossa editech.<br /><br />

Nosso propósito é transformar pessoas por meio da educação, porque acreditamos que pessoas curiosas, com senso crítico, criatividade e autonomia, ancoradas em valores humanos e na aprendizagem contínua, são capazes de transformar o mundo.<br /><br />

Por isso, somos arrojados e crescemos em ritmo acelerado, investindo fortemente na digitalização, na internacionalização e na premiunização ou diferenciação de todas nossas operações, voltadas para diferentes segmentos de ensino e para diferentes classes socioeconômicas.<br /><br />

Temos muito orgulho da nossa origem. Nascemos do empreendedorismo de nossos fundadores, um casal de educadores visionário que sempre colocou a qualidade do ensino em primeiro lugar e enxergou a escola como um potencial agente de integração de diferentes vivências e culturas.<br /><br />

Não é à toa que, em nossos mais de 60 anos, os alunos estão no centro de todas as nossas decisões, e investimos fortemente em tecnologia e no intraempreendedorismo como aliados da melhor experiência aos estudantes e famílias.<br /><br />

Venha conhecer nosso ecossistema de educação e transformar pessoas e o mundo com a gente!



 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Conheça mais sobre a vaga 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Aqui no Grupo SEB, a área de Design Organizacional faz parte da Estrutura Corporativa de Gente e Gestão e atua em parceria com todas as unidades de negócios. Por isso, nossa pessoa de Modelo de Gestão e Analytics vem para contribuir na construção e no monitoramento de KPIs estratégicos e OKR’s, acompanhar metas organizacionais de todas as unidades de negócio. <br /><br />


Além disso, atuará em projetos de diferentes naturezas, contribuindo na gestão do cronograma e interagindo com os stakeholders dos projetos.<br /><br />

A área de Modelo de Gestão e Analytics também é responsável por auxiliar as unidades de negócio a alcançar suas metas e objetivos.

                                                      

 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        O que se espera do candidato
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Graduação completa ou em fase de conclusão em administração, economia, matemática ou áreas afins.<br /><br />

Conhecimento de Excel e de Business Intelligence (desejável).<br /><br />
Como somos uma empresa global, será muito bom se você tiver um bom domínio da língua inglesa.<br /><br />

Nesta oportunidade, a interação com diversas áreas da empresa é imprescindível, o que é uma excelente oportunidade para conhecer como o Grupo SEB opera em todas as suas verticais. Então, se você é uma pessoa comunicativa, trabalha bem em equipe, tem pensamento crítico e criativo, empatia e, principalmente, adora resolver problemas, aqui é o seu lugar!



 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Modelo de trabalho
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Ribeirão Preto - SP Presencial.
 
        </div>
      
      </Container>


      
 
</div>


    </>
  );
}

export default Main;
