import React from "react";
import ReactDOM from "react-dom";
import {history} from './views/history';
import { Router, Route, Switch } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
// pages
import Index from "views/Index.js";
import Index2 from "views/Index2.js";

// others

ReactDOM.render(
  <Router history={history}>
    <Switch>
    <Route exact path="/" component={Index} />   
    <Route path="/oportunidades" component={Index2} />
      <Route path="*" component={() => <h1>Página não encontrada!</h1>} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
