import React, { useState } from "react";
import Cookies from 'js-cookie';

// reactstrap components
import { Container, Row, Col, Modal, Button } from "reactstrap";
import Video from "../../assets/img/video.png";

import "../../../node_modules/video-react/dist/video-react.css";





// core components

function IndexHeader() {
  const [largeModal, setLargeModal] = React.useState(false); 
  
  const [show, setShow] = useState(true);


  const clickCLoseButton = () => {
    Cookies.set('closed', true, { expires: 7})
    setShow((s) => !s)
  };


  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/bg_head.jpg").default + ")",
        }}
      >

        
          <Container>
            <Row>
              <Col sm style={{textAlign:"center",}}>
              <a
   
        onClick={() => setLargeModal(true)}
        style={{cursor:"pointer"}}
      >
        <img src={Video} width="100%" style={{backgroundColor:"transparent"}} />  
      </a>

      <Modal
        isOpen={largeModal}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        toggle={() => setLargeModal(false)}
      >
       
  
         <iframe width="99.99%" height="450" src="https://www.youtube.com/embed/AfKcMRoICXY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
   
      </Modal>
              
              </Col>
              <Col sm style={{textAlign:"right", fontSize:16, color:"#fff"}}>
               <div style={{fontSize:30, fontWeight:500, marginTop:30}}>
               Os primeiros 
               </div>
               <div style={{fontSize:70, fontWeight:600, marginTop:-20}}>
               jovens  
               </div>
               <div style={{fontSize:90, fontWeight:600, marginTop:-50}}>
               talentos
               </div>
               <div style={{fontSize:26, fontWeight:400, marginTop:-20}}>
               já estão transformando o mundo por meio da Educação.
               </div>
               <div style={{marginTop:-10}}>
               <br /><br /><strong>Agora chegou a sua vez. <br />Você conectado com o propósito que nos move.</strong>

               </div>
               <div>
               <h6 style={{textAlign:"right", fontSize:16, fontWeight:400, paddingTop:40, textTransform:"none"}} >
        © {new Date().getFullYear()}. Grupo SEB
        </h6> </div>
              </Col>
            </Row>
          </Container>
        
    
      
      
      </div>


      

      <div style={{ display: show ? "block" : "none" }}>
      <div style={{position:"absolute", bottom:380, right:380, zIndex:9999999999999}}>
        <div style={{position:"fixed", lineHeight:2, fontSize:12, padding:20, borderRadius:14, marginRight:20, backgroundColor:"#662D91", color:"#fff"}}>

        O site do Grupo SEB usa “Cookies Operacionais de Sessão ou Persistentes” para o funcionamento do sistema, que podem armazenar seus dados pessoais indiretamente. Conforme detalhado nas <a href="https://sebsa.com.br/privacidade-cookies" style={{color:"#fff", fontWeight:600}} target="_blank">“Políticas de Cookies e Política de Privacidade”</a> e de acordo com o Art. 5 da LGPD (Lei 13.709/2018) solicitamos seu consentimento para prosseguir. Sua rejeição pode implicar no não funcionamento das tecnologias do site. Você aceita os Cookies de Sessão em seu navegador?
       
       <Row>
  
        <Col sm>
        <div style={{textAlign:"right", marginTop:20, marginBottom:10}}>
        <Button
              className="btn-round js-close"
              style={{color:"#fff", borderColor:"#fff", fontSize:12, fontWeight:400, textTransform:"none"}}
              onClick={clickCLoseButton}
              outline
            >
              Sim, eu aceito.
            </Button>&nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              className="btn-round"
              style={{color:"#fff", borderColor:"#fff", fontSize:12, fontWeight:400, textTransform:"none"}}
              onClick={clickCLoseButton}
              outline
            >
              Não, eu rejeito.
            </Button>
          </div>
        </Col>
        </Row>
        
        </div>
      </div>
      </div>
    </>
  );
}

export default IndexHeader;
